import React, { useState } from "react";
import { RichText } from "prismic-reactjs";

import {
  Box,
  FlexGrid,
  H2,
  H4,
  H6,
  Radio,
  SectionWrapper,
  Text
} from "src/components";

const StaffItem = ({ name, role, email }) => (
  <Box>
    <Text children={name} fontSize={3} />
    <H6 children={role} mt={2} color="text.alt" />
    <Text mt={2} fontSize={1} fontWeight={600}>
      <a children={email} href={`mailto:` + email} />
    </Text>
  </Box>
);

function Categories({ categories }) {
  const [activeCategory, setActiveCategory] = useState(-1);
  return (
    <>
      <SectionWrapper
        id="staff"
        py={[8, 9, 10]}
        bg="bg.default"
        css={`
          border-top: 1px solid ${props => props.theme.colors.bg.alt};
        `}
      >
        <H2 children={`Find the right person`} />
        {categories && (
          <section
            css={`
              width: 100%;
              margin: ${props => props.theme.space[5] + "px"} 0;
              padding: ${props => props.theme.space[3] + "px"};
              border: 1px solid ${props => props.theme.colors.bg.alt};
              border-radius: 4px;
              ${props => props.theme.mediaQueries.small} {
                padding: ${props => props.theme.space[5] + "px"};
              }
            `}
          >
            <FlexGrid gutterX={[2]} gutterY={[2]}>
              <Box width={[1 / 2, 1 / 3]}>
                <Radio
                  children={"All"}
                  value={-1}
                  checked={-1 === activeCategory}
                  for="activeCategory"
                  onClick={() => {
                    setActiveCategory(-1);
                  }}
                />
              </Box>
              {categories.map((category, index) => (
                <Box width={[1 / 2, 1 / 3]}>
                  <Radio
                    children={category.primary.category}
                    key={"category" + index}
                    value={index}
                    checked={index === activeCategory}
                    for="activeCategory"
                    onClick={() => {
                      setActiveCategory(index);
                    }}
                  />
                </Box>
              ))}
            </FlexGrid>
          </section>
        )}
        <FlexGrid as="section" gutterX={[7]} gutterY={[5, 7]}>
          {categories.map((category, index) => {
            if (index === activeCategory || activeCategory === -1) {
              return (
                <>
                  {category.items.map((item, staffIndex) => {
                    if (item.staffDetails) {
                      return (
                        <Box
                          width={[1, 1 / 2]}
                          key={"category" + index + "staff" + item.staff.id}
                        >
                          <StaffItem
                            name={
                              item.staffDetails.name &&
                              RichText.asText(item.staffDetails.name)
                            }
                            role={item.staffDetails.role}
                            email={item.staffDetails.email}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              );
            } else {
              return null;
            }
          })}
        </FlexGrid>
      </SectionWrapper>
    </>
  );
}

export default Categories;
