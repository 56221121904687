import React from "react";
import {
  mdiFacebookBox,
  mdiTwitter,
  mdiInstagram,
  mdiSnapchat,
  mdiYoutube,
} from "@mdi/js";
import { hideVisually } from "polished";

import {
  Box,
  FlexCol,
  FlexGrid,
  H6,
  Icon,
  RichText,
  PrismicLink,
  SectionWrapper,
  Text,
  Modal,
} from "src/components";

const SocialButton = ({ icon, label, link }) => (
  <PrismicLink link={link}>
    <div
      css={`
        width: 40px;
        div {
          transition: transform 250ms ${(props) => props.theme.easing};
        }
        svg {
          transition: opacity 125ms ${(props) => props.theme.easing};
        }
        @media not all and (hover: none) {
          &:hover {
            div {
              transform: scale(0.95);
              transform-origin: center;
            }
            svg {
              opacity: 0.75;
            }
          }
        }
      `}
    >
      <div
        css={`
          position: relative;
          padding-bottom: 100%;
          border-radius: 9999px;
          color: ${(props) => props.theme.colors.text.default};
          background-color: ${(props) => props.theme.colors.bg.alt};
          cursor: pointer;
          transition: transform 500ms ${(props) => props.theme.easing};

          svg {
            transition: opacity 350ms ${(props) => props.theme.easing};
          }

          @media not all and (hover: none) {
            &:hover {
              transform: scale(0.9);
              transform-origin: center;

              svg {
                opacity: 0.5;
              }
            }
          }
        `}
      >
        <Icon
          path={icon}
          size={1.25}
          css={`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: currentColor;
          `}
        />
        <Text
          children={label}
          css={`
            ${hideVisually()};
          `}
        />
      </div>
    </div>
  </PrismicLink>
);

const Info = ({
  officeHours,
  address,
  addressNotes,
  phone,
  phoneNotes,
  email,
  emailNotes,
  facebook,
  twitter,
  instagram,
  snapchat,
  youtube,
  artistSubmissionInformation,
}) => (
  <>
    <SectionWrapper id="office" maxWidth={0} py={[8, 9, 10]} bg="bg.default">
      <FlexGrid width={1} gutterX={[5, 7]} gutterY={[5]}>
        {address && (
          <Box width={[1, 1 / 2]}>
            <H6 children={`Address`} />
            <Box mt={2}>
              <RichText children={address} fluid fontSize={2} />
            </Box>
            {addressNotes && (
              <Text
                children={addressNotes}
                mt={2}
                fontSize={1}
                color="text.alt"
              />
            )}
          </Box>
        )}
        {email && (
          <Box width={[1, 1 / 2]}>
            <H6 children={`Email`} />
            {email && <Text children={email} fluid mt={2} fontSize={2} />}
            {emailNotes && (
              <Text
                children={emailNotes}
                my={2}
                fontSize={1}
                color="text.alt"
              />
            )}
            {artistSubmissionInformation && (
              <Modal
                trigger={
                  <Text
                    children={`More info`}
                    fontSize={1}
                    color="text.alt"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  />
                }
                children={
                  <FlexCol p={[10]}>{artistSubmissionInformation}</FlexCol>
                }
              />
            )}
          </Box>
        )}
        {officeHours && (
          <Box width={[1, 1 / 2]}>
            <H6 children={`Office hours`} />
            <Box mt={2}>
              <RichText children={officeHours} fluid fontSize={2} />
            </Box>
          </Box>
        )}
        {phone && (
          <Box width={[1, 1 / 2]}>
            <H6 children={`Phone`} />
            {phone && (
              <Text
                children={phone}
                fluid
                mt={2}
                fontSize={2}
                css={`
                  font-variant-numeric: tabular-nums;
                `}
              />
            )}
            {phoneNotes && (
              <Text
                children={phoneNotes}
                mt={2}
                fontSize={1}
                color="text.alt"
              />
            )}
          </Box>
        )}
        <Box width={[1, 1 / 2]}>
          <H6 children={`Follow`} />
          <Box mt={3}>
            <FlexGrid gutterX={[1]}>
              {facebook && (
                <SocialButton
                  icon={mdiFacebookBox}
                  label={`Facebook`}
                  link={facebook}
                />
              )}
              {twitter && (
                <SocialButton
                  icon={mdiTwitter}
                  label={`Twitter`}
                  link={twitter}
                />
              )}
              {instagram && (
                <SocialButton
                  icon={mdiInstagram}
                  label={`Instagram`}
                  link={instagram}
                />
              )}
              {snapchat && (
                <SocialButton
                  icon={mdiSnapchat}
                  label={`Snapchat`}
                  link={snapchat}
                />
              )}
              {youtube && (
                <SocialButton
                  icon={mdiYoutube}
                  label={`YouTube`}
                  link={youtube}
                />
              )}
            </FlexGrid>
          </Box>
        </Box>
      </FlexGrid>
    </SectionWrapper>
  </>
);

export default Info;
