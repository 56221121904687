import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";
import { PageHeaderV2, SEO, RibbonCTA } from "src/components";

import Info from "./_components/_info";
import Categories from "./_components/_categories";

const ContactPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        navigation: prismicNavigation {
          dataString
        }
        contactPage: prismicContact {
          dataString
        }
        staffMembers: allPrismicStaffMember {
          edges {
            node {
              prismicId
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let navigation = JSON.parse(data.navigation.dataString);
      let page = JSON.parse(data.contactPage.dataString);
      if (preview) {
        page = preview;
      }
      if (data.staffMembers && page.body) {
        data.staffMembers.edges.forEach((staff) => {
          page.body.forEach((department) => {
            department.items.forEach((item) => {
              if (item.staff.id === staff.node.prismicId) {
                item.staffDetails = JSON.parse(staff.node.dataString);
              }
            });
          });
        });
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={
              page.opengraph_title && RichText.asText(page.opengraph_title)
            }
            description={
              page.opengraph_description &&
              RichText.asText(page.opengraph_description)
            }
          />
          <PageHeaderV2
            heading={page.intro && RichText.asText(page.intro)}
            links={["Office", "Staff"]}
            image={page.contact_image_primary}
            color="brand.altPrimary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <Info
            officeHours={
              page.office_hours
                ? RichText.render(page.office_hours, linkResolver, serializer)
                : ""
            }
            address={
              page.address
                ? RichText.render(page.address, linkResolver, serializer)
                : ""
            }
            addressNotes={page.address_notes}
            phone={page.phone}
            phoneNotes={page.phone_notes}
            email={page.email}
            emailNotes={page.email_notes}
            facebook={
              navigation.facebook &&
              navigation.facebook.url &&
              navigation.facebook
            }
            twitter={
              navigation.twitter && navigation.twitter.url && navigation.twitter
            }
            instagram={
              navigation.instagram &&
              navigation.instagram.url &&
              navigation.instagram
            }
            youtube={
              navigation.youtube && navigation.youtube.url && navigation.youtube
            }
            snapchat={
              navigation.snapchat &&
              navigation.snapchat.url &&
              navigation.snapchat
            }
            artistSubmissionInformation={
              page.artist_submission_information
                ? RichText.render(
                    page.artist_submission_information,
                    linkResolver,
                    serializer
                  )
                : ""
            }
          />
          <Categories categories={page.body} />
          <RibbonCTA
            heading={page.spaces_header && RichText.asText(page.spaces_header)}
            body={
              page.spaces_description &&
              RichText.render(page.spaces_description, linkResolver, serializer)
            }
            buttonText={page.spaces_disclosure_button_text}
            buttonLink={page.spaces_disclosure_button_link}
            color="bg.wash"
            // invert={true}
          />
          <RibbonCTA
            heading={page.cta_header && RichText.asText(page.cta_header)}
            body={page.cta_subtext}
            buttonText={page.cta_button_text}
            buttonLink={page.cta_button_link}
            color="brand.primary"
            invert={true}
          />
        </>
      );
    }}
  />
);

export default ContactPage;
